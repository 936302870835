exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-reads-tsx": () => import("./../../../src/pages/reads.tsx" /* webpackChunkName: "component---src-pages-reads-tsx" */),
  "component---src-pages-residents-tsx": () => import("./../../../src/pages/residents.tsx" /* webpackChunkName: "component---src-pages-residents-tsx" */),
  "component---src-templates-archive-tsx": () => import("./../../../src/templates/archive.tsx" /* webpackChunkName: "component---src-templates-archive-tsx" */),
  "component---src-templates-queer-worldings-tsx": () => import("./../../../src/templates/queerWorldings.tsx" /* webpackChunkName: "component---src-templates-queer-worldings-tsx" */),
  "component---src-templates-rave-tsx": () => import("./../../../src/templates/rave.tsx" /* webpackChunkName: "component---src-templates-rave-tsx" */),
  "component---src-templates-resident-tsx": () => import("./../../../src/templates/resident.tsx" /* webpackChunkName: "component---src-templates-resident-tsx" */),
  "component---src-templates-zine-tsx": () => import("./../../../src/templates/zine.tsx" /* webpackChunkName: "component---src-templates-zine-tsx" */)
}


module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-analytics@5.14.0_gatsby@5.14.0_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-148730856-1","head":false,"anonymize":true,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lecken","short_name":"Lecken","start_url":"/","background_color":"#030305","theme_color":"#ff7fc9","display":"standalone","icon":"/opt/build/repo/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"96f0df9252f318c755125e337a56c08c"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.6.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
